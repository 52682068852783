import LayerCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerCommandRepositoryInterface'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'
import store from '@/store'
import { AddLayer } from '@/services/MapEditor/infrastructure/store/modules/Layer/action-types'

export default class LayerCommandRepository implements LayerCommandRepositoryInterface
{
    insert(item: LayerDTO): Promise<void>
    {
        return store.dispatch(new AddLayer(item));
    }
}
